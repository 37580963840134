@import './src/scss/colors.scss';

.contact-us-page {
  padding-top: 130px;
  background-color: #f5f5f5;
  min-height: calc(100vh - 114px);

  .content-container {
    max-width: 900px;
    margin: 0px auto;
  }

  .title {
    text-align: center;
    font-size: 45px;
    line-height: 55px;
    color: #262626;
    margin-top: 0px;
    margin-bottom: 30px;
    font-weight: bold;
  }
  
  .description {
    margin: 0 auto;
    width: 100%;
    max-width: 900px;
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0;
    font-weight: 100;
    padding: 0px 30px;
  }

  .success-message {
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0;
    font-weight: 100;
    padding: 60px;
    width: 100%;
    border-radius: 7.5px;
    background-color: white;
    margin-top: 45px;
    margin-bottom: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
  }

  .notification-container {
    width: 100%;
    top: 80px;

    .notification-warning {
      background-color: $orange;
    }

    .notification-success {
      background-color: #00c343;
    }

    .notification-error {
      background-color: #de2525;
    }
  }

  .contact-us-page-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 240px;
    // position: fixed;
  }
  .form {
    padding: 60px;
    width: 100%;
    border-radius: 7.5px;
    background-color: white;
    min-height: 400px;
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;

    label {
      span {
        margin-left: 5px;
        color: #de2525;
      }
    }

    .questions-form-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      gap: 30px;
      padding-bottom: 30px;
    }

    .phone-input-select {
      position: relative;
      .PhoneInputCountry {
        height: 100%;
        position: absolute;
        left: 10px;
      }

      & > input {
        padding-left: 64px !important;
      }
    }
    .submit-container {
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;

      small {
        margin-top: 10px;
        color: #757d82;
      }

      button {
        margin-top: 25px;
        width: 80%;
        transition: 0.1s;

        &:hover {
          background-color: rgba(252, 129, 36, 0.1);
          border: 1px solid $orange;
          color: $orange;
        }

        &.disabled {
          cursor: not-allowed;
          background-color: rgba(252, 129, 36, 0.1);
          border: 1px solid rgba(252, 129, 36, 0.5);
          color: rgba(252, 129, 36, 0.5);
        }
      }
    }

    .contact-info {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 30px 30px 0;
      gap: 30px;
      grid-template-rows: 65px 65px;

      label {
        color: #4d4d4d;
      }

      @media (max-width: 655px) { 
        .input-mobile {
          grid-column: span 2;
        }
      }

      .input-mobile:last-child {
        grid-column: span 2;
      }

      .input-mobile input,
      .input-date .input-wrapper {
        margin-top: 5px;
      }

      .input-mobile:has(.has-error) {
        & input {
          border: 1px solid red;
        }
        & span {
          color: red;
        }
      }

      .input-mobile,
      .input-date,
      .input-time-slot {
        text-align: start;
      }
      .input-time-slot {
        z-index: 11;

        .calendar-container {
          position: absolute;
        }
      }
      .input-date {
        .input-wrapper {
          position: relative;
          color: white;

          input::-webkit-inner-spin-button,
          input::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
          }

          .icon-container {
            display: inline-flex;
            position: absolute;
            right: 0;
            background-color: $orange;
            width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            border-radius: 0 7.5px 7.5px 0;
            svg {
              font-size: 30px;
            }
          }
        }
      }
      .input-mobile,
      .input-date {
        input::-webkit-date-and-time-value {
          text-align: left;
          padding-left: 8px;
        }

        #date {
          pointer-events: none;
          min-width: calc(100% - 32px);
        }

        input {
          font-size: 16px;
          color: #757d82;
          padding-left: 16px;
          width: 100%;
          height: 40px;
          background: #ffffff 0 0 no-repeat padding-box;
          border: 1px solid #d6d8e1;
          border-radius: 5px;
          opacity: 1;

          &:focus {
            outline: none;
          }
        }

        textarea {
          font-family: Arial, Helvetica, sans-serif;
          font-size: 16px;
          color: #757d82;
          padding: 16px;
          width: 100%;
          margin-top: 8px;
          background: #ffffff 0 0 no-repeat padding-box;
          border: 1px solid #d6d8e1;
          border-radius: 5px;
          opacity: 1;

          &:focus {
            outline: none;
          }
        }
      }
    }
    .checkboxes {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

  //media for screens less 1120px

  @media (max-width: 1120px) {
    .about-us-page-header {
      width: unset;
      height: unset;
      margin: 50px 10px 30px 10px;
      position: unset;

      h1 {
        margin: 0 0 10px 0;
        font-size: 36px;
      }

      p {
        margin: 0;
      }
    }
  }
}