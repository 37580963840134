@import './fonts.scss';
@import './utils.scss';
@import './colors.scss';

html {
  margin: 0 auto;
}
//@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
//  html {
//    transform: rotate(-90deg);
//    transform-origin: left top;
//    width: 100vh;
//    overflow-x: hidden;
//    position: absolute;
//    top: 100%;
//    left: 0;
//  }
//}

body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

@media (min-width: $lg-breakpoint) {
  .MuiContainer-maxWidthLg {
    max-width: 1450px !important;
  }
}

.btn-orange {
  background-color: $orange;
  color: white;
  border: 0;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  height: 50px;
  border-radius: 25px;
}

.dashboard-top-block {
  height: 190px;
  background: transparent linear-gradient(270deg, $orange 0%, $orange 100%);
  display: flex;
  align-items: center;
  padding: 20px 60px;
  color: white;
  font-weight: bold;

  font-size: 45px;
  line-height: 50px;
}

.dashboard-breadcrumbs {
  height: 43px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  padding: 5px 60px;
  box-shadow: 0 8px 16px #00000012;
  background-color: $main-gray;
  color: $main-black;
  position: relative;

  img {
    height: 7px;
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
    color: $main-black;
  }
}

.selected-sort-option {
  background-color: $orange;
}
